import React, { useState, useEffect } from "react"
import styled from "styled-components"
import EllipsisLoader from "../../generic/loaders/elipsis-loader"

const payfastPaymentUrl =
  process.env.GATSBY_PAYFAST_PAY_URL || "https://www.payfast.co.za/eng/process"

// ***********************
// TODO: The commented code can be used for Payfast "onsite" mode (untested)

const PayfastPayment = ({ orderData, paymentData }) => {
  const [formData, setFormData] = useState()
  const [loading, setLoading] = useState(true)
  // const [retryTimer, setRetryTimer] = useState()

  // // Trigger the payment method request and wait for paymentData to be set
  // useEffect(() => {
  //   handlePaymentMethodSelected(setLoading)
  //   setReadyToContinue(false)

  //   return clearTimeout(retryTimer)
  // }, [])

  // // Check if Payfast script is loaded
  // const checkScript = () => {
  //   if (
  //     typeof window !== "undefined" &&
  //     typeof window.payfast_do_onsite_payment === "function"
  //   ) {
  //     console.log("payfast script successfully loaded")
  //     window.payfast_do_onsite_payment({ uuid: paymentData.uuid }, (result) => {
  //       console.log("payfast result: ", result)
  //       if (result === true) {
  //         // Payment Completed, redirect to order success
  //         navigate(`/order-status/?orderid=${orderData.id}&p`)
  //       } else {
  //         // Payment Window Closed, remove payment data and trigger back button
  //         setPaymentData(null)
  //         backButton()
  //       }
  //     })
  //   } else {
  //     waitAndCheckScript()
  //   }
  // }

  // // Wait and check script again
  // const waitAndCheckScript = () => {
  //   console.log("payfast script not loaded, retrying in 2000ms")
  //   clearTimeout(retryTimer)
  //   setRetryTimer(
  //     setTimeout(() => {
  //       checkScript()
  //     }, 2000)
  //   )
  // }

  // Once the payment data is ready
  useEffect(() => {
    if (paymentData && paymentData.uuid) {
      //// If uuid is set, we are using onsite integration method
      // clearTimeout(retryTimer)
      // checkScript()
    } else if (paymentData && paymentData.merchant_id) {
      // If merchant_id is set, we are using form submit method
      setFormData(paymentData)
      setLoading(false)
    }
  }, [paymentData])

  // Attempt to auto submit form when possible. Submit button can still be manually clicked as fallback
  useEffect(() => {
    if (formData) {
      try {
        document.getElementById("payfast_form").submit()
        setLoading(true)
      } catch { }
    }
  }, [formData])

  // Show loader if loading
  if (loading)
    return (
      <EllipsisLoader
        message="Preparing your order..."
        height="500px"
        speed={1}
      />
    )

  // Form submit mode. You don't actually see the form, it's hidden
  if (formData)
    return (
      <div className="checkout-button">
        <StyledForm
          className="fade-in"
          action={payfastPaymentUrl}
          method="post"
          id="payfast_form"
        >
          {Object.keys(formData).map(
            (key) =>
              key !== "order" && (
                <input type="hidden" name={key} value={formData[key]} />
              )
          )}
          <div>
            Your order is ready for payment. Please click below to continue to
            Payfast and pay for your order.
          </div>
          <StyledSubmitButton type="submit" value="Continue to Payfast" />
        </StyledForm>
      </div>
    )

  return null

  // // Onsite mode widget
  // return (
  //   <div className="checkout-button">
  //     <div>
  //       {`Follow the instructions below to complete payment${
  //         orderData ? ` for order ` : ""
  //       }`}
  //       {orderData && (
  //         <LinkWrapper to="/my-account/">{`${orderData.order_number}`}</LinkWrapper>
  //       )}
  //       {` `}
  //     </div>
  //     <btn
  //       onClick={() => {
  //         // setPaymentData(null)
  //         // backButton()
  //       }}
  //     >
  //       Back
  //     </btn>
  //     {paymentData && paymentData.uuid ? (
  //       <Helmet key="payfast-script">
  //         <script src="https://www.payfast.co.za/onsite/engine.js"></script>
  //       </Helmet>
  //     ) : null}
  //     <div className="checkout-button">
  //       <EllipsisLoader
  //         message="Preparing your payment..."
  //         height="500px"
  //         speed={1}
  //       />
  //     </div>
  //   </div>
  // )
}

export default PayfastPayment

// ===============
//     STYLES
// ===============
export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  // This fade in is actually used to hide the flashing when submitting the form lol
  &.fade-in {
    animation: fadeIn 2s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
export const StyledSubmitButton = styled.input`
  height: 70px;
  min-width: 250px;
  margin: 10px 0;
  border: 1px solid #e0182d;
  background-color: #e0182d;
  color: white;
  border-radius: 50px;
  font-weight: 600;
  cursor: pointer;
`
