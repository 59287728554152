import React from "react"

import PaygatePayment from "../../components/checkout/payment-methods/paygate-payment"
import PayfastPayment from "../../components/checkout/payment-methods/payfast-payment"

const PaymentFrame = ({ paymentMethod, paymentData, orderData }) => {
  // This wrapper only exists to make it easier to add more payment methods
  if (paymentMethod == "paygate") {
    return <PaygatePayment orderData={orderData} paymentData={paymentData} />
  } else if (paymentMethod == "payfast") {
    return <PayfastPayment orderData={orderData} paymentData={paymentData} />
  }

  // Fallback
  return null
}

export default PaymentFrame
